import { render, staticRenderFns } from "./OfficialFoot.vue?vue&type=template&id=531a7ba1&scoped=true"
import script from "./OfficialFoot.vue?vue&type=script&lang=js"
export * from "./OfficialFoot.vue?vue&type=script&lang=js"
import style0 from "./OfficialFoot.vue?vue&type=style&index=0&id=531a7ba1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531a7ba1",
  null
  
)

export default component.exports