<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  import 'bootstrap/dist/css/bootstrap.min.css'
  export default{
    name: "App"
  }
</script>

<style lang="less">
</style>
