import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueLazyload from 'vue-lazyload'
import Axios from 'axios'
import config from '../config/config.js'
import VideoPlayer from 'vue-video-player'
import VueAwesomeSwiper from 'vue-awesome-swiper'
 
// import style
import 'swiper/dist/css/swiper.css'
import './assets/css/common.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueLazyload)
Vue.prototype.$http=Axios
Vue.prototype.TzConfig = config
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
