<template>
  <!-- footer -->
  <div class="layout footer">
    <div class="footer-box">
      <div class="footer-up"></div>
      <div class="footer-left">
        <div class="footer-logo"></div>
        <p>
          {{ homePageSetting.copyright
          }}<a :href="homePageSetting.websiteRecordNumberLink">{{
            homePageSetting.websiteRecordNumber
          }}</a>
          <a href="https://ipw.cn/ipv6webcheck/?site=www.glsytzjt.com/" title="本站支持IPv6访问" target='_blank'>
            <img alt="本站支持IPv6访问" src="https://static.ipw.cn/icon/ipv6-certified-s1.svg">
          </a>
        </p>
      </div>
      <div class="footer-contact no1">
        <p>
          <span>公司地址</span><span>{{ homePageSetting.companyAddress }}</span>
        </p>
        <p>
          <span>公司电话</span><span>{{ homePageSetting.telephone }}</span>
        </p>
        <p>
          <span>公司传真</span><span>{{ homePageSetting.faxNumber }}</span>
        </p>
      </div>
      <div class="footer-code">
        <div class="footer-code-item">
          <img :src="homePageSetting.subscriptionImgUrl" />
          <p>{{ homePageSetting.subscriptionTitle }}</p>
        </div>
        <div class="footer-code-item">
          <img :src="homePageSetting.serviceNumberImgUrl" />
          <p>{{ homePageSetting.serviceNumberTitle }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfficialFoot",
  props: {
    homePageSetting: {
      type: Object,
    },
  },
  data: function () {
    return {};
  },
  mounted() {},
  created() {},
  methods: {
    routerLink(url) {
      //顶部导航栏跳转
      if (!url) {
        return false;
      }
      //网站内路由跳转
      if (url.indexOf("/") === 0) {
        this.$router.push({ path: url });
      } else if (url.indexOf("http") === 0) {
        //外站连接
        window.open(url, "_self");
      }
      return false;
    },
  },
};
</script>

<style scoped>
@charset "utf-8";
.banner {
  width: 100%;
  height: 900px;
}
.banner >>> .swiper-container {
  width: 100%;
  height: 900px;
}
.banner >>> .swiper-slide {
  display: block;
  width: 100%;
  height: 900px;
}
.banner >>> .swiper-container-autoheight,
.banner >>> .swiper-container-autoheight .swiper-slide {
  height: 100%;
}
.hidden-xs {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hidden-mx {
  display: none;
}
.banner >>> .swiper-slide .video-player,
.banner >>> .vjs-custom-skin > .video-js {
  display: block;
  width: 100%;
  height: 100%;
}
.banner >>> .swiper-pagination-bullet {
  width: 44px;
  height: 2px;
  background: #fff;
  border-radius: 0;
  opacity: 1;
}
.banner >>> .swiper-pagination-bullet-active {
  background: #f9d688;
}
.banner >>> .swiper-pagination-fraction,
.swiper-pagination-custom,
.banner >>> .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 58px;
}
.banner
  >>> .swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 2px;
}
.banner >>> .swiper-button-prev,
.banner >>> .swiper-button-next {
  margin-top: -44px;
  width: 88px;
  height: 88px;
  background-color: #f9d688;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 38px;
  border-radius: 50%;
}
.banner >>> .swiper-button-next,
.banner >>> .swiper-button-prev {
  background-image: url(../assets/images/arr-right-y.png);
}
.banner >>> .swiper-button-prev {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.banner >>> .swiper-button-prev.swiper-button-disabled,
.banner >>> .swiper-button-next.swiper-button-disabled {
  opacity: 0.56;
}
.banner >>> .swiper-button-next {
  right: 56px;
}
.banner >>> .swiper-button-prev {
  left: 56px;
}

.layout {
  width: 100%;
}
.box {
  margin: 0 auto;
  width: 1440px;
}
.introduction {
  padding: 158px 0 0;
}
.introduction p {
  text-align: center;
}
.introduction p:nth-child(1) {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
}
.introduction p:nth-child(2) {
  margin: 14px auto 26px;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
}
.introduction p:nth-child(3) {
  font-size: 18px;
  line-height: 36px;
}
.introduction p:nth-child(4) {
  margin: 32px 0 0;
}
.introduction p:nth-child(4) a {
  position: relative;
  padding: 0 38px;
  font-size: 18px;
  line-height: 36px;
}
.introduction p:nth-child(4) a:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: inline-block;
  content: "";
  width: 24px;
  height: 24px;
  background: #f9d688 url(../assets/images/arr-right-y.png) no-repeat
    center/14px;
  border-radius: 50%;
}

.news {
  margin: 188px auto 0;
  background: url(../assets/images/bg-news.png) no-repeat center top/100% auto;
}
.title {
  padding: 80px 0 37px;
  width: 100%;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  text-align: center;
}
.small-title {
  margin: 0 0 38px;
  padding: 0 0 10px;
  width: 100%;
  height: 42px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  background: url(../assets/images/title.png) no-repeat bottom left/160px 6px;
}
.column2 {
  display: flex;
  display: -webkit-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0 0 55px 0;
  width: 100%;
}
.column2-left {
  width: 58.33%;
}
.column2-left >>> .swiper-slide {
  display: block;
  width: 100%;
  height: 560px;
}
.column2-left >>> .swiper-pagination-fraction,
.swiper-pagination-custom,
.column2-left .swiper-container-horizontal > .swiper-pagination-bullets {
  left: initial;
  right: 38px;
  bottom: 40px;
  width: auto;
  height: 16px;
}
.column2-left >>> .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  opacity: 1;
}
.column2-left >>> .swiper-pagination-bullet-active {
  background: #4181f4;
}
.column2-left
  >>> .swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 4px;
}

.cl-item {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.cl-item img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cl-item-title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: 32px 28% 32px 3%;
  width: 100%;
  height: 17%;
  background: rgba(0, 0, 0, 0.6);
}
.cl-item-title h4 {
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.column2-right {
  width: calc(100% - 58.33%);
}
.column2-right ul {
  display: flex;
  display: -webkit-flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #fff;
}
.cr-item {
  position: relative;
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 20px;
  width: 100%;
  height: 20%;
}
.cr-item:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background: #eee;
}
.cr-item:last-of-type:before {
  display: none;
}
.column2-right .cr-item-active:hover {
  background: #0b46b1;
}
.cr-item-title {
  width: 100%;
}
.cr-item-title h4 {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /*！ autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}
.cr-item-active:hover .cr-item-title h4 {
  color: #fff;
}

.column3 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}
.m-column3 {
  display: none;
}
.module {
  position: relative;
  padding: 0 3%;
  width: 33.33%;
}
.module:first-of-type {
  padding: 0 3% 0 0;
}
.module:before {
  position: absolute;
  right: 0;
  top: 16px;
  bottom: 0;
  display: block;
  content: "";
  width: 1px;
  background: #eee;
}
.module:last-of-type:before {
  display: none;
}
.module-item {
  margin: 0 0 20px 0;
  width: 100%;
}
.module-item:last-of-type {
  margin: 0;
}
.module-item-title {
  width: 100%;
}
.module-item-title h4 {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.module-item-title p {
  width: 100%;
  font-size: 13px;
  line-height: 24px;
  color: rgba(29, 29, 31, 0.5);
}
.module-item-title:hover h4,
.module-item-title:hover p {
  color: #0b46b1;
}

.business {
  margin: 188px auto 0;
  background: url(~@/assets/images/bg-business.png) no-repeat center top/100%
    640px;
}
.business-text {
  padding: 135px 0 135px;
  width: 100%;
  text-align: center;
}
.business-text p {
  color: #fff;
}
.business-text p:nth-child(1) {
  font-size: 24px;
  line-height: 31px;
}
.business-text p:nth-child(2) {
  padding: 14px 0 0;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
}
.business-text p:nth-child(3) {
  padding: 24px 0 0;
  font-size: 18px;
  line-height: 36px;
}
.business-card {
  position: relative;
  margin: 0 auto;
  display: flex;
  display: -webkit-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  max-width: 1440px;
  min-height: 520px;
}
.m-business-card {
  display: none;
}
.business-card:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  display: block;
  content: "";
  width: 96%;
  height: 20px;
  border-radius: 10px;
  box-shadow: 0 20px 60px rgba(11, 70, 177, 0.5);
}
.business >>> .el-tabs--border-card {
  border: 0;
  box-shadow: rgba(0, 0, 0, 0);
}
.business-card >>> .el-tabs__header {
  width: 34.44444444444444%;
}
.business-card >>> .el-tabs__nav-wrap.is-scrollable {
  padding: 0;
  height: 100%;
}
.business >>> .business-card .el-tabs__item {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(100% / 3);
  background-color: #fff;
}
.business >>> .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  border: 0;
}
.business
  >>> .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:first-child,
.business
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item
  + .el-tabs__item {
  margin: 0;
}
.business
  >>> .el-tabs--top.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:nth-child(2),
.business
  .el-tabs--bottom
  .el-tabs--left
  > .el-tabs__header
  .el-tabs__item:last-child,
.business
  >>> .el-tabs--bottom
  .el-tabs--right
  > .el-tabs__header
  .el-tabs__item:last-child,
.business
  >>> .el-tabs--bottom.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:last-child,
.el-tabs--bottom.el-tabs--card > .el-tabs__header .el-tabs__item:last-child,
.el-tabs--top .el-tabs--left > .el-tabs__header .el-tabs__item:last-child,
.business
  >>> .el-tabs--top
  .el-tabs--right
  > .el-tabs__header
  .el-tabs__item:last-child,
.business
  >>> .el-tabs--top.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:last-child,
.business
  .el-tabs--top.el-tabs--card
  > .el-tabs__header
  .el-tabs__item:last-child {
  padding: 0;
}
.business-card >>> .el-tabs__nav-prev,
.business-card >>> .el-tabs__nav-next {
  display: none;
}
.business-card >>> .el-tabs__nav-wrap,
.business-card >>> .el-tabs__nav-scroll {
  display: block;
  height: 100%;
}
.business-card >>> .el-tabs__nav {
  display: flex;
  display: -webkit-flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.business >>> .is-active {
  background-image: linear-gradient(
    -90deg,
    rgba(81, 157, 255, 1) 16%,
    rgba(0, 0, 0, 0) 84%
  );
}
.business-tab {
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 9%;
  height: 100%;
}
.bt-serial {
  width: 23%;
  font-size: 64px;
  color: rgba(11, 70, 177, 0.24);
}
.business >>> .is-active .bt-serial {
  color: rgba(11, 70, 177, 1);
}
.bt-title {
  width: 66%;
}
.bt-title span {
  display: block;
  width: 100%;
  color: #212121;
}
.bt-title span:first-of-type {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}
.bt-title span:last-of-type {
  font-size: 14px;
  line-height: 24px;
  white-space: normal;
}
.business-card >>> .el-tabs__content,
.business >>> .el-tabs--border-card > .el-tabs__content {
  padding: 0;
  width: 65.625%;
}
.business >>> .el-tab-pane,
.business .business-box {
  height: 100%;
  background-color: #fff;
}
.business >>> .el-tabs--border-card > .el-tabs__header {
  border: 0;
}
.business-box {
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  width: 100%;
}
.business-box6 .business-item,
.business-box3 .business-item {
  width: calc(100% / 3);
}
.business-box6 .business-item {
  height: calc(100% / 2);
}
.business-box2 .business-item {
  width: calc(100% / 2);
}
.business-box3 .business-item,
.business-box2 .business-item {
  height: 100%;
}
.bi-link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.bi-link img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bi-link p {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 26px;
  display: block;
  z-index: 3;
  padding: 0 44% 0 7%;
  width: 100%;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
}
.bi-link::after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  z-index: 2;
  content: "";
  width: 100%;
  height: 100%;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  background-image: linear-gradient(
    45deg,
    rgba(41, 85, 187, 0.8) 16%,
    rgba(41, 85, 187, 0) 84%
  );
}

.planning {
  position: relative;
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin: 175px 0 0;
  width: 100%;
}
.planning-video,
.planning-video .video-player,
.planning-video .vjs-custom-skin > .video-js {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 640px;
}
.planning-img {
  display: none;
}
.planning-link {
  position: absolute;
  left: 14.47916666666667%;
  z-index: 222;
}
.planning-link a {
  position: relative;
  padding: 0 0 134px;
  display: inline-block;
}
.planning-link a p {
  color: #fff;
}
.planning-link a p:nth-child(1) {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}
.planning-link a p:nth-child(2) {
  margin: 5px 0 0;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
}
.planning-link a p:nth-child(3) {
  margin: 46px 0 0;
  font-size: 16px;
  line-height: 20px;
}
.planning-link a:after {
  position: absolute;
  left: 0;
  display: block;
  content: "";
  margin: 46px 0 0;
  width: 88px;
  height: 88px;
  background: #f9d688 url(../assets/images/arr-right-y.png) no-repeat
    center/38px;
  border-radius: 50%;
}
.m-link {
  display: none;
}
.link {
  display: -webkit-flex;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 84px auto 0;
  max-width: 1440px;
}
.link a {
  display: block;
}
.link a img {
  display: block;
  width: 100%;
}

@media (max-width: 1500px) {
  .box {
    max-width: auto;
    width: 100%;
  }
  .introduction {
    padding: 158px 15px 0;
  }
  .news,
  .business {
    padding: 0 15px;
  }
  .link {
    margin: 84px 15px 0;
    max-width: auto;
    width: calc(100% - 30px);
  }
  .link a {
    width: calc((100% - 30px) / 3);
  }
}

@media (max-width: 1250px) {
  .bi-link p {
    padding: 0 30% 0 7%;
  }
}
@media (max-width: 1150px) {
  .business-tab {
    padding: 0 0 0 5%;
  }
}
@media (max-width: 1024px) {
  .banner {
    margin: 66px 0 0;
    height: 546px;
  }
  .banner >>> .swiper-container,
  .banner >>> .swiper-container-autoheight .swiper-wrapper {
    width: 100%;
    height: 100%;
  }
  .banner >>> .swiper-container-autoheight,
  .banner >>> .swiper-container-autoheight .swiper-slide {
    display: block;
    width: 100%;
    height: 100%;
  }
  .bi-link p {
    padding: 0 20% 0 5%;
  }
}
@media (max-width: 990px) {
  .banner {
    margin: 64px 0 0;
  }
  .banner >>> .swiper-button-prev,
  .banner >>> .swiper-button-next {
    display: none;
  }
  .hidden-xs {
    display: none;
  }
  .hidden-mx {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .introduction {
    padding: 100px 15px 0;
  }
  .news {
    margin: 100px auto 0;
  }
  .business {
    margin: 80px auto 0;
  }
  .business-card {
    min-height: 380px;
  }
  .business-card >>> .el-tabs__header {
    width: 26%;
  }
  .business-card >>> .el-tabs__content,
  .business >>> .el-tabs--border-card > .el-tabs__content {
    width: 74%;
  }
  .bt-title {
    margin: 0 0 0 4%;
  }
  .bt-serial {
    font-size: 40px;
  }
  .bt-title span:last-of-type {
    display: none;
  }
  .planning-video {
    display: none;
  }
  .planning-img {
    position: relative;
    display: block;
    width: 100%;
  }
  .planning-img img {
    display: block;
    width: 100%;
  }
  .bi-link p {
    padding: 0 20% 0 5%;
  }
}

@media (max-width: 768px) {
  .banner >>> .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .banner >>> .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0.58rem;
  }
  .banner >>> .swiper-pagination-bullet {
    height: 0.04rem;
  }
  .introduction {
    padding: 80px 15px 0;
  }
  .news {
    margin: 80px auto 0;
  }
  .title {
    padding: 50px 0 20px;
  }
  .small-title {
    margin: 0 0 20px;
  }
  .column2 {
    flex-flow: column wrap;
  }
  .column2-left {
    width: 100%;
  }
  .column2-right {
    width: 100%;
  }
  .cr-item {
    padding: 24px;
  }
}

@media (max-width: 640px) {
  .banner {
    margin: 0.96rem 0 0;
  }
  .news {
    margin: 1.2rem auto 0;
    background: none;
  }
  .title {
    padding: 0 0 0.3rem;
    font-size: 0.48rem;
    line-height: 0.62rem;
  }
  .small-title {
    margin: 0 0.48rem;
    padding: 0 0 0.36rem;
    width: calc(100% - 0.48rem * 2);
    font-size: 0.36rem;
    line-height: 0.64rem;
    background: none;
  }
  .news,
  .business {
    padding: 0;
  }
  .introduction {
    padding: 1rem 0.96rem 0;
  }
  .introduction p:nth-child(1) {
    font-size: 0.28rem;
    line-height: 0.4rem;
  }
  .introduction p:nth-child(2) {
    margin: 0.1rem auto 0.5rem;
    font-size: 0.48rem;
    line-height: 0.62rem;
  }
  .introduction p:nth-child(3) {
    font-size: 0.24rem;
    line-height: 0.4rem;
  }
  .introduction p:nth-child(4) {
    margin: 0.6rem auto 0;
  }
  .introduction p:nth-child(4) a {
    padding: 0 0.4rem;
    height: 0.4rem;
    font-size: 0.26rem;
    line-height: 0.48rem;
  }
  .introduction p:nth-child(4) a:after {
    width: 0.32rem;
    height: 0.32rem;
    background: #f9d688 url(../assets/images/arr-right-y.png) no-repeat
      center/.2rem;
    border-radius: 50%;
  }

  .column2 {
    margin: 0 0 0.3rem;
  }
  .column2-left >>> .swiper-slide {
    height: 6.76rem;
  }
  .column2-left >>> .swiper-slide img {
    width: 100%;
    height: 5rem;
    object-fit: cover;
  }
  .column2-left >>> .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .column2-left .swiper-container-horizontal > .swiper-pagination-bullets {
    left: 0;
    right: 0;
    bottom: 2rem;
    height: 0.16rem;
  }
  .column2-left >>> .swiper-pagination-bullet {
    vertical-align: top;
    width: 0.16rem;
    height: 0.16rem;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  .column2-left >>> .swiper-pagination-bullet-active {
    background-color: #fff;
  }
  .column2-left
    >>> .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 0.08rem;
  }
  .cl-item {
    padding: 0 0 1.76rem;
    height: 6.76rem;
  }
  .cl-item-title {
    padding: 0.32rem 0.48rem;
    height: 1.76rem;
    background-color: #f4f6f8;
  }
  .cl-item-title h4 {
    width: 100%;
    font-size: 0.36rem;
    font-weight: 700;
    line-height: 0.56rem;
    color: #212121;
    line-height: 0.56rem;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    /*！ autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
  }

  .cr-item {
    padding: 0.4rem 0.48rem;
    height: auto;
  }
  .cr-item-title {
    display: block;
    height: auto;
  }
  .cr-item-title h4 {
    font-size: 0.36rem;
    line-height: 0.56rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .cr-item:before {
    display: none;
  }

  .column3 {
    display: none;
  }
  .m-column3 {
    display: block;
    margin: 0.5rem 0 0;
    width: 100%;
  }
  .m-column3-box {
    padding: 0.8rem 0 0;
    width: 100%;
  }
  .m-column3-box >>> .swiper-pagination {
    top: 0;
    bottom: auto;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    width: 100%;
    height: 0.8rem;
    border-bottom: 1px solid #eee;
  }
  .m-column3-box >>> .swiper-pagination-bullet {
    position: relative;
    display: inline-block;
    width: auto;
    height: 0.8rem;
    font-size: 0.36rem;
    font-weight: 700;
    line-height: 0.64rem;
    background: none;
    opacity: 1;
  }
  .m-column3-box >>> .swiper-pagination-bullet:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1px;
    display: inline-block;
    content: "";
    width: 100%;
    height: 0.06rem;
    background: #fff;
  }
  .m-column3-box >>> .swiper-pagination-bullet-active:after {
    background: #1f5ed1;
  }
  .m-column3-box
    >>> .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0;
  }
  .m-column3-box >>> .swiper-slide ul {
    padding: 0.48rem;
  }
  .module-item {
    margin: 0 0 20px 0;
    width: 100%;
  }
  .m-column3-item {
    margin: 0 0 0.58rem;
  }
  .m-column3-title {
    width: 100%;
  }
  .m-column3-title h4 {
    font-size: 0.36rem;
    font-weight: 400;
    line-height: 0.56rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .m-column3-title p {
    font-size: 0.24rem;
    line-height: 0.32rem;
    color: rgba(29, 29, 31, 0.5);
  }

  .business {
    margin: 0.24rem auto 0;
    background: url(../assets/images/bg-business@2x.png) no-repeat center
      top/100% 9.6rem;
  }
  .business-text {
    padding: 1.4rem 0.96rem 1.2rem;
    width: 100%;
  }
  .business-text p:nth-child(1) {
    font-size: 0.24rem;
    line-height: 0.38rem;
  }
  .business-text p:nth-child(2) {
    padding: 0.2rem 0 0;
    font-size: 0.48rem;
    line-height: 0.62rem;
  }
  .business-text p:nth-child(3) {
    padding: 0.48rem 0 0;
    font-size: 0.24rem;
    line-height: 0.4rem;
  }

  .business-card {
    display: none;
  }
  .m-business-card {
    display: block;
    width: 100%;
  }
  .m-bc-box {
    padding: 1.28rem 0 0;
    width: 100%;
  }
  .m-bc-box >>> .swiper-pagination {
    top: 0;
    bottom: auto;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 0.48rem;
    width: 100%;
    height: 0.8rem;
    border-bottom: 1px solid #eee;
  }
  .m-bc-box >>> .swiper-pagination-bullet {
    position: relative;
    display: inline-block;
    width: auto;
    height: 0.8rem;
    font-size: 0.36rem;
    font-weight: 700;
    line-height: 0.64rem;
    background: none;
    opacity: 1;
    color: #fff;
  }
  .m-bc-box >>> .swiper-pagination-bullet:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1px;
    display: inline-block;
    content: "";
    width: 100%;
    height: 0.06rem;
    background: transparent;
  }
  .m-bc-box >>> .swiper-pagination-bullet-active:after {
    background: #f9d688;
  }
  .m-bc-box
    >>> .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0;
  }
  .m-bc-box .business-box {
    display: flex;
    display: -webkit-flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    padding: 0 0.48rem;
    width: 100%;
    height: 8rem;
    background: transparent;
  }
  .m-bc-box .business-box6 .business-item {
    width: calc(100% / 2);
    height: calc(100% / 3);
  }
  .m-bc-box .business-box3 .business-item {
    width: 100%;
    height: calc(100% / 3);
  }
  .m-bc-box .business-box2 .business-item {
    width: 100%;
    height: calc(100% / 2);
  }
  .bi-link {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  .bi-link img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .bi-link p {
    bottom: 0;
    display: block;
    z-index: 3;
    padding: 0 0.32rem;
    height: 1rem;
    font-size: 0.26rem;
    line-height: 0.4rem;
    text-align: center;
  }
  .bi-link:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    z-index: 2;
    content: "";
    width: 100%;
    height: 100%;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    background-image: linear-gradient(
      45deg,
      rgba(41, 85, 187, 0.8) 16%,
      rgba(41, 85, 187, 0) 84%
    );
  }

  .planning {
    position: relative;
    margin: 1rem 0 0;
    width: 100%;
    height: 5.6rem;
  }
  .planning-img {
    height: 5.6rem;
  }
  .planning-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .planning-link {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.74rem;
    padding: 0;
    width: 42%;
  }
  .planning-link a {
    padding: 0 0 1.2rem;
  }
  .planning-link a p:nth-child(1) {
    font-size: 0.26rem;
    line-height: 0.34rem;
  }
  .planning-link a p:nth-child(2) {
    margin: 0.1rem 0 0;
    font-size: 0.42rem;
    line-height: 0.54rem;
  }
  .planning-link a p:nth-child(3) {
    margin: 0.3rem 0 0;
    font-size: 0.18rem;
    line-height: 0.4rem;
  }
  .planning-link a:after {
    margin: 0.4rem 0 0;
    width: 0.8rem;
    height: 0.8rem;
    background: #f9d688 url(../assets/images/arr-right-y.png) no-repeat
      center/.4rem;
  }

  .m-link-box {
    margin: 0.3rem 0 0;
  }
  .bt-title span:first-of-type {
    font-size: 0.48rem;
    line-height: 0.64rem;
  }
  .bt-title span:last-of-type {
    font-size: 0.28rem;
    line-height: 0.48rem;
  }
  .link {
    display: none;
  }
  .m-link {
    display: block;
  }
  .m-link-box >>> .swiper-pagination {
    bottom: 0.14rem;
    height: 0.16rem;
  }
  .m-link-box >>> .swiper-pagination-bullet {
    vertical-align: top;
    width: 0.16rem;
    height: 0.16rem;
    background-color: transparent;
    border: 0.02rem solid rgba(255, 255, 255, 0.5);
    opacity: 1;
  }
  .m-link-box >>> .swiper-pagination-bullet-active {
    background-color: #fff;
  }
  .m-link-box
    >>> .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 0.08rem;
  }
  .m-mb-link {
    display: block;
  }
}
</style>
