import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue')
  },
  {
    path: '/companyList',
    name: 'companyList',
    component: () => import('../views/CompanyList.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/Search.vue')
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('../views/Business.vue')
  },
  {
    path: '/plainTextNews',
    name: 'plainTextNews',
    component: () => import('../views/PlainTextNews.vue')
  },
  {
    path: '/smallPictureNews',
    name: 'smallPictureNews',
    component: () => import('../views/SmallPictureNews.vue')
  },
  {
    path: '/bigPictureNews',
    name: 'bigPictureNews',
    component: () => import('../views/BigPictureNews.vue')
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/importantdetail',
    name: 'importantdetail',
    component: () => import('../views/ImportantDetail.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/Detail.vue')
  },
  {
    path: '/party',
    name: 'party',
    component: () => import('../views/Party.vue')
  },
  {
    path: '/partyHistory',
    name: 'partyHistory',
    component: () => import('../views/PartyHistory.vue')
  },
  {
    path: '/partyPolicy',
    name: 'partyPolicy',
    component: () => import('../views/PartyPolicy.vue')
  },
  {
    path: '/mien',
    name: 'mien',
    component: () => import('../views/Mien.vue')
  },
  {
    path: '/culture',
    name: 'culture',
    component: () => import('../views/Culture.vue')
  },
  {
    path: '/VerticalPictureNews',
    name: 'verticalPictureNews',
    component: () => import('../views/VerticalPictureNews.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
