let BASE_URL = "http://localhost:18081";
switch(process.env.NODE_ENV) {
  case 'dev':
      BASE_URL = "https://api.glsytzjt.com";
      break
  case 'test':
      BASE_URL = "https://api.glsytzjt.com"
      break
  case 'production':
      BASE_URL = "https://api.glsytzjt.com"
      // BASE_URL = "http://testapi.glsytzjt.com"
      break
}


module.exports = {
  BASE_URL: BASE_URL
};
