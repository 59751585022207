export function getHomePageSetting() {
  return {
    //路由
    navigationList: [],
    //轮播广告
    carouseAdList: [],
    //关于公司简介
    about: {},
    // 特别企划
    specialPlan: {
      title: undefined,
      subTitle: undefined,
      linkUrl: undefined,
      briefList: [],
    },
    // 联系我们
    contactUs: {
      title: undefined,
      postcode: undefined,
      bannerImgUrl: undefined,
      mapImgUrl: undefined,
      briefList: [],
    },
    // 子公司列表页
    subsidiaryListPage: {
      title: undefined,
      bannerImgUrl: undefined,
      briefList: [],
    },
    //资讯动态
    //要闻类型文章
    hardNewsArticleList: [],
    //党建类型文章
    partyArticleList: [],
    //子公司类型文章
    subsidiaryArticleList: [],
    //通知公告类型文章
    noticeArticleList: [],
    //主营及子公司
    mainAndSub: {},
    //主营分类
    navigationList: [],
    //子公司介绍
    navigationList: [],
    //视频广告
    videoAd: {},
    //党建广告
    partyAdlList: [],
    //logo图片地址
    logoImgUrl: undefined,
    //版权说明
    copyright: undefined,
    //网站备案号
    websiteRecordNumber: undefined,
    //网站备案号跳转地址
    websiteRecordNumberLink: undefined,
    //公司地址
    companyAddress: undefined,
    //联系电话
    telephone: undefined,
    //公司传真
    faxNumber: undefined,
    //订阅号标题
    subscriptionTitle: undefined,
    //订阅号图片地址
    subscriptionImgUrl: undefined,
    //服务号标题
    serviceNumberTitle: undefined,
    //服务号图片地址
    serviceNumberImgUrl: undefined,
    //seo标题
    seoTitle: undefined,
    //seo摘要描述
    seoDescription: undefined,
    //seo关键词
    seoKeyword: undefined,
  };
}


export function setSeo(seoTitle,seoDescription,seoKeyword) {
  document.querySelector('title').innerHTML = seoTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', seoDescription)
  document.querySelector('meta[name="keywords"]').setAttribute('content', seoKeyword)
}
